<template>
    <div>
        <Row :gutter="8" class="p-b-5">
            <i-col span="12">
              <i-input size="small" placeholder="关键字：审批名称模糊查询" v-model="query.key"></i-input>
            </i-col>
            <i-col span="6">
              <i-select size="small" placeholder="审批状态" clearable v-model="query.status">
                <i-option value="-1">废弃</i-option>
                <i-option value="0">草稿</i-option>
                <i-option value="4">审批中</i-option>
                <i-option value="5">已通过</i-option>
                <i-option value="6">已拒绝</i-option>
              </i-select>
            </i-col>
            <i-col span="6">
              <i-button size="small" icon="ios-search" type="primary" @click="handleSearch">搜索</i-button>
              <i-button @click="CreateApproval" class="m-l-5" type="success"  size="small">创建新审批</i-button>
            </i-col>
        </Row>

        <Table size="small" :columns="approvalColumns" :data="approvalData" stripe></Table>
        <div class="paging_style">
          <Page size="small" :total="total" show-total show-elevator  :current="query.pageNumber" :page-size="query.pageSize" @on-change="handleChangePage"></Page>
        </div>

        <Modal v-model="showModal" width="400">
          <p slot="header"  class="workplatform-title">
            <span v-if="isCancel">撤回审批</span>
            <span v-else>作废审批</span>
          </p>
          <div >
            <p v-if="isCancel">您确认要撤回该审批任务？</p>
            <p v-else>您确认要作废该审批任务？此操作将发起一个作废审批流程</p>
            <i-input v-model="description" type="textarea" class="m-t-10" placeholder="请输入审批备注"></i-input>
          </div>
          <div slot="footer">
            <i-button type="primary" size="large" @click="sumitCancelApproval">确认</i-button>
          </div>
        </Modal>
    </div>
</template>

<script>
import { allFileApproval, cancelApproval, invalidApproval } from '@/api/sys/approval'
import { formatSpecialApprovalStatus } from '@/utils/tagStatus'

export default {
  data () {
    return {
      approvalColumns: [
        { title: '名称', key: 'name' },
        { title: '审批发起人', key: 'userName' },
        { title: '发起时间', key: 'createTime' },
        { title: '审批内容', key: 'content', ellipsis: true },
        {
          title: '状态',
          width: 120,
          align: 'center',
          render: (h, data) => {
            if (data.row.status === 5 && data.row.reviewing) {
              return h('div', [
                formatSpecialApprovalStatus(h, data.row.status, data.row.statusName),
                h('p', { style: { color: '#ef4f4f' } }, '作废审批中')
              ])
            } else {
              return formatSpecialApprovalStatus(h, data.row.status, data.row.statusName)
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.showDetails(params)
                  }
                }
              }, '详情'),

              (params.row.userId === this.userId && (params.row.status === 0 || params.row.status === 2 || params.row.status === 6)) // 草稿重新编辑
                ? h('a', {
                  style: {
                    'margin-left': '5px'
                  },
                  on: {
                    click: () => {
                      this.$store.commit('set_approvalCreate_detailId', params.row.id)
                    }
                  }
                }, '编辑') : '',
              (params.row.userId === this.userId && params.row.reviewing)
                ? h('a', {
                  style: {
                    'margin-left': '5px',
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.isCancel = true
                      this.description = ''
                      this.approvalId = params.row.id
                      this.showModal = true
                    }
                  }
                }, '撤回') : '',
              (params.row.status === 5 && !params.row.reviewing)
                ? h('a', {
                  style: {
                    'margin-left': '5px',
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.isCancel = false
                      this.description = ''
                      this.approvalId = params.row.id
                      this.showModal = true
                    }
                  }
                }, '作废') : ''
            ])
          }
        }
      ],
      approvalData: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        agentCompanyId: this.$store.getters.token.userInfo.companyId,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        companyId: this.$store.getters.token.userInfo.companyId,
        userId: null,
        key: '',
        status: null
      },
      showModal: false,
      description: '',
      approvalId: 0,
      isCancel: false,
      userId: this.$store.getters.token.userInfo.userId
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      allFileApproval(this.query).then(res => {
        this.approvalData = res.list
        this.total = res.totalRow
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    handleChangePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    CreateApproval () {
      this.$store.commit('set_approvalCreate_detailId', null)
    },
    showDetails (params) {
      this.$store.commit('set_approvalCreate_pageType', 'detail')
      this.$store.commit('set_approvalCreate_detailId', params.row.id)
    },
    sumitCancelApproval () {
      const postData = {
        approvalId: this.approvalId,
        mark: this.description
      }

      if (this.isCancel) {
        cancelApproval(postData).then(res => {
          if (res && !res.errcode) {
            this.showModal = false
            this.$Notice.success({ desc: '撤销审批成功！' })
            this.initPageData()
          }
        })
      } else {
        invalidApproval(postData).then(res => {
          if (res && !res.errcode) {
            this.showModal = false
            this.$Notice.success({ desc: '已提交作废审批！' })
            this.initPageData()
          }
        })
      }
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.approvalCreate.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.handleSearch()
    }
  }
}
</script>
